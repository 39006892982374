import styled from "styled-components"

const iframeContainer = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 40%;
  margin: 2em;
`

export default iframeContainer
