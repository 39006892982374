import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import Layout from "@utils/layout"
import SEO from "@utils/seo"
import Img from "gatsby-image"
import Button from "@atoms/button"
import ImageCaption from "@atoms/imageCaption"
import CheckoutButton from "@atoms/checkoutButton"
import ContentBlock from "@atoms/contentBlock"
import Container from "@atoms/container"
import List from "@atoms/list"
import Form from "@atoms/form"
import Subheading from "@atoms/subheading"
import SectionHeader from "@molecules/sectionHeader"
import SectionSetup from "@molecules/sectionSetup"
import IframeContainer from "@atoms/iframeContainer"
import Iframe from "@atoms/iframe"

const CoursePage = () => {
  const data = useStaticQuery(graphql`
    query CourseImagesQuery {
      moonclerk: file(relativePath: { eq: "thumb.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      gatsbyjs: file(relativePath: { eq: "image01.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      netlify: file(relativePath: { eq: "image05.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      markus: file(relativePath: { eq: "image06.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      acess: file(relativePath: { eq: "collage.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="course" />
      <SectionHeader />
      <SectionSetup />
      <ContentContainer content grid gridSplit>
        <Image>
          <Img fluid={data.moonclerk.childImageSharp.fluid} />
        </Image>
        <ContentBlock>
          <Subheading>Whats in it for you</Subheading>
          <h2>Stand out</h2>
          <p>
            It's hard to really stand out on Social Media and create that wow
            effect nowadays.
          </p>
          <p>
            You have to get creative, like really creative, and this course will
            teach you a technique that is guaranteed to WOW your audience or
            clients.
          </p>
          <p>
            I will teach you how to create stunning levitating food photography
            and will guide you the whole way. Together, we go over how to prep,
            what to shop. We cover equipment, setup, techniques, settings and
            editing. The best part?
          </p>
          <p>We're going to have fun while doing all of that.</p>
          <Button href="#content" aria-label="content">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path d="M12 24l-8-9h6v-15h4v15h6z" />
            </svg>
          </Button>
        </ContentBlock>
      </ContentContainer>
      <ContentContainer content grid gridSplit>
        <Image>
          <Img fluid={data.acess.childImageSharp.fluid} />
        </Image>
        <ContentBlock id="content">
          <Subheading>What you will learn</Subheading>
          <h2>What's in the course?</h2>
          <p>
            I want to take you from knowing a bit of photography to creating
            stunning images that will make even the most hardened Instagram user
            stop scrolling.
          </p>
          <p>We will do three projects together and you will learn how to:</p>
          <List>
            <li>arrange everything and set the scene for your food shots</li>
            <li>what equipment you need to get the shots you want</li>
            <li>
              how to use your hands (and Photoshop) to create this special
              effect
            </li>
            <li>
              which settings to choose so your image comes out bright and sharp
            </li>
          </List>
          <Button href="#about" aria-label="about">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path d="M12 24l-8-9h6v-15h4v15h6z" />
            </svg>
          </Button>
        </ContentBlock>
      </ContentContainer>
      <ContentContainer id="about" content grid gridSplit>
        <ContentBlock>
          <Subheading>The levitating food guy</Subheading>
          <h2>Johannes</h2>
          <Image>
            <Img fluid={data.netlify.childImageSharp.fluid} />
          </Image>
          <ImageCaption>
            Hi, I'm the levitating food guy. I've been making tutorials on food
            photography and videography for years. Now it's finally the time for
            a real in-depth course on my favorite technique, Levitating Food
            Photography. That's what you brought here ;). I'm looking forward to
            help you up your Food Photography skills.
          </ImageCaption>
        </ContentBlock>
        <ContentBlock>
          <Subheading>The creative photography nerd</Subheading>
          <h2>Markus</h2>
          <Image>
            <Img fluid={data.markus.childImageSharp.fluid} />
          </Image>
          <ImageCaption>
            Markus is a good friend of mine, and a photographer who enjoys
            sharing his passion for images by teaching. He has a peanut allergy,
            but apart from that he's a great guy. With Markus on board, we can
            elevate (get it?) the creativity to new levels and I am happy to
            have him join me for some screen time in this class.
          </ImageCaption>
        </ContentBlock>
      </ContentContainer>
      <ContentContainer id="checkout" content grid gridSplit>
        <IframeContainer>
          <Iframe
            src="https://www.youtube.com/embed/qpyVL2s9Mcc?controls=0"
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></Iframe>
        </IframeContainer>
        <ContentBlock>
          <Subheading>join us</Subheading>
          <h2>Claim your discount</h2>
          <p>
            The course will launch for 139€ in late October. Claim your special
            Pre-Launch price with a 65% discount and be the first to access the
            course.
          </p>
          <form
            name="Contact Form"
            method="POST"
            data-netlify="true"
            action="/checkout"
          >
            <input type="hidden" name="form-name" value="Contact Form" />
            <Form>
              <label>Your Email: </label>
              <input type="email" name="email" required />
              <div>
                <input
                  type="checkbox"
                  id="agree"
                  name="agree"
                  value="agree"
                  required
                />
                <label for="agree">
                  &nbsp; contact me in regards to the course (required)
                </label>
              </div>
              <CheckoutButton type="submit">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path d="M18 10v-4c0-3.313-2.687-6-6-6s-6 2.687-6 6v4h-3v14h18v-14h-3zm-5 7.723v2.277h-2v-2.277c-.595-.347-1-.984-1-1.723 0-1.104.896-2 2-2s2 .896 2 2c0 .738-.404 1.376-1 1.723zm-5-7.723v-4c0-2.206 1.794-4 4-4 2.205 0 4 1.794 4 4v4h-8z" />
                </svg>
                CLAIM 65% DISCOUNT
              </CheckoutButton>
            </Form>
          </form>
        </ContentBlock>
      </ContentContainer>
    </Layout>
  )
}

export default CoursePage

const Image = styled.div``

const ContentContainer = styled(Container)`
  &:nth-of-type(odd) {
    direction: rtl;

    div {
      direction: ltr;
    }
  }
`
