import React from "react"
import styled from "styled-components"
import { media } from "@utils/media"
import Subheading from "@atoms/subheading"
import IframeContainer from "@atoms/iframeContainer"
import Iframe from "@atoms/iframe"
import CheckoutButton from "@atoms/checkoutButton"

const SectionHeader = () => {
  return (
    <HeaderContainer contentTop content grid backgroundSecondary>
      <IframeContainer>
        <Iframe
          src="https://www.youtube.com/embed/LZzNIOrl4ME?controls=0"
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></Iframe>
      </IframeContainer>
      <Content>
        <Subheading>Kickstart your creativity</Subheading>
        <h1>Levitating Food Photography Course</h1>
        <a href="#checkout">
          <CheckoutButton>Request access</CheckoutButton>
        </a>
      </Content>
    </HeaderContainer>
  )
}

export default SectionHeader

const HeaderContainer = styled.header`
  align-items: center;
  background-color: ${props => props.theme.colors.secondary};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 100vh;
  padding: 0 var(--spacingContent);

  @media ${media.lg} {
    flex-direction: row-reverse;
    min-height: 800px;
  }
`

const Content = styled.div`
  display: block;
  flex-basis: 100%;
  width: 100%;

  @media ${media.lg} {
    width: 40%;
    flex-basis: 40%;
  }

  h1 {
    margin-bottom: 3rem;
  }
`
